.logoPrincipal {
    color: #FAF8F1;
    font-family: serif;
    text-align: center;
    font-size: 6rem;
    font-weight:100;
}

h2 {
    color: #472183;
}

p {
    text-align: center;
}

h2 {
    text-align: center;
}

.proximamente {
    align-self: center;
}