.footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    background-color: #472183;
    padding: 20px;
}

.contacto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logotipo {
    font-family: serif;
    font-size: 1.5rem;
    font-weight: lighter;
}

.whatsappFooter {
    display: flex;
    align-items: flex-start;
    justify-content:center;
}

a {
    color: white;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}